import './App.css';
// import Layout from './components/Layout/Layout';
import { Routes, Route } from 'react-router-dom';


function App() {
  return (
    <div className="main-container">
      <Routes>
        {/* <Route path="/" element={<Layout />} /> */}
        <Route path="/" element={<div></div>} />
        </Routes>
    </div>
  );
}

export default App;
